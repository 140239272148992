import React from 'react'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Obfuscate from 'react-obfuscate'

const KontaktPage = props => (
  <Layout>
    <div className="page">
      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            <p className="sosc-header">
              Kontakt
            </p>
            <div className="dash" />
            <p>
              Sie haben die passende Immobilie gefunden und wollen ein Besichtigungstermin vereinbaren?
              Sie haben Rückfragen zu einem Objekt?
              Sie wollen eine Immobilie verkaufen und wünschen professionelle Unterstützung?
              <br /><br />
              Nehmen Sie gerne Kontakt mit mir auf:
              <br /><br />
              Frank Vogl<br />
              E-Mail: <Obfuscate className="dark-link" linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." email="frank-vogl@t-online.de" /><br/>
              Telefon: <Obfuscate className="dark-link" linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." tel="0151-64717999" />
            </p>
          </div>
          <div>
            <Img
              fluid={props.data.imageOne.childImageSharp.fluid}
              className="hero-image"
              alt="hero image"
            />
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageFirst: file(relativePath: { eq: "images/logo_grad.png" }) {
      ...firstSiteImages
    }
    imageOne: file(relativePath: { eq: "images/undraw_conversation_h12g_transparent.png" }) {
      ...firstSiteImages
    }
    imageTwo: file(relativePath: { eq: "images/hero_clubs.png" }) {
      ...firstSiteImages
    }
    imageThree: file(relativePath: { eq: "images/hero_github.png" }) {
      ...firstSiteImages
    }
    imageFour: file(relativePath: { eq: "images/hero_connect_.png" }) {
      ...firstSiteImages
    }
  }
`

export default KontaktPage
